import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { Organization } from 'src/models/organization';
import { MessageService } from 'src/services/mail-message.service';
import { JobOffer } from '../../../models/jobOffer';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss']
})
export class ContactModalComponent implements OnInit {
  @Input() orgId!: number;
  @Input() jobId!: number;
  @Input() job!: JobOffer;
  @Input() organization!: Organization;
  @ViewChild('content') content!: ElementRef;
  success: boolean = false;
  disabled = false;
  siteKey = environment.captchaKey;
  handleSuccess($event: string) {
    //throw new Error('Method not implemented.');
  }
  protected form!: FormGroup;
  constructor(private formBuilder: FormBuilder, private msgService: MessageService, private modalService: NgbModal) { 
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required,  Validators.minLength(10)]],
      recaptcha: ['', Validators.required] 
    })
  }

  close() {
    this.modalService.dismissAll();
  }

  ngOnInit(): void {
  }

  open() {
    this.modalService.open(this.content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, windowClass: 'pxp-user-modal' }).result.then();
  }

  onSubmit() {
    this.disabled = true;
    if(this.orgId) {
      this.msgService.sendMailToOrganization(this.orgId, this.form.value).subscribe(res => {
        if(res) {
          this.success = true;
        } else {
          this.disabled = false;
        }
      })
    }
    if(this.jobId) {
      this.msgService.sendMailToContactPerson(this.jobId, this.form.value).subscribe(res => {
        if(res) {
          this.success = true;
        } else {
          this.disabled = false;
        }
      })
    }
  }

}
