<div *ngIf="job.isAtHome">
    Zonnedorp en omgeving
</div>
<div *ngIf="!job.isAtHome && job.jobLocation">
    <span class="mb-0" *ngIf="job.locationTypeId==2">Op verplaatsing</span>
    <span class="mb-0" *ngIf="job.locationTypeId!=2">{{ job.jobLocation.name }}</span>
    <!--<br />
  {{ job.jobLocation.street }} {{ job.jobLocation.number}}
  <br />
  {{ job.jobLocation.postCode }} {{ job.jobLocation.location }}-->
</div>