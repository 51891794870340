<!--<ng-template #content let-modal>-->
<div class="modal-header">
    <span *ngIf="step == 1 || step == 2" class="modal-title mt-4 fs-3" id="modal-basic-title">
    Stel je kandidaat voor<br />{{ job.name}}
</span>
    <span *ngIf="step == 3" class="modal-title text-center mt-4 fs-3" id="modal-basic-title">
  Bedankt dat je wil helpen!
</span>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
</div>
<div class="modal-body pt-4">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div *ngIf="step == 1">

            <!--skills-->
            <div *ngIf="skillForms && skillForms.length>0">
                <h5 class="mb-4">
                    <span *ngIf="job.skills.length == 1">Voor deze vacature is er 1 vereiste.<br />Vink deze aan als deze voor jou van toepassing is.<br />Je kan je enkel kandidaat stellen als je hieraan voldoet.</span>
                    <span *ngIf="job.skills.length > 1">Voor deze vacature zijn er {{job.skills.length}} vereisten.<br />Vink aan wat voor jou van toepassing is.<br />Je kan je enkel kandidaat stellen als je aan alle vereisten voldoet.</span>
                </h5>
                <div class="mb-5">
                    <div class="ps-1">
                        <div class="form-check" *ngFor="let skill of skillForms.controls">
                            <input class="form-check-input" type="checkbox" id="skill{{asSkillForm(skill).id.value}}" [formControl]="asSkillForm(skill).selected">
                            <label class="form-check-label" for="skill{{asSkillForm(skill).id.value}}">
                                {{asSkillForm(skill).name.value}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <!--tasks-->
            <div *ngIf="taskForms && taskForms.length>0">
                <h4 class="mb-4">
                    Vink aan voor welke taken je je kandidaat wil stellen.
                </h4>
                <div class="mb-5" *ngFor="let task of taskForms.controls; index as i">
                    <h5 style="">
                        <div class="d-flex align-items-center">
                            <input class="form-check-input mt-0" type="checkbox" value="" id="task{{asTaskForm(task).id.value}}" [formControl]="asTaskForm(task).selected">
                            <label for="task{{asTaskForm(task).id.value}}" class="ms-2">Taak {{i+1}}: {{ asTaskForm(task).name.value }}</label>
                        </div>
                    </h5>
                    <div class="ms-4 ps-1" *ngIf="asTaskForm(task).selected.value">
                        <div class="mb-2">
                            <span *ngIf="asTaskForm(task).skills.length == 1">Voor deze taak is er 1 vereiste. Vink deze aan als deze voor jou van toepassing is. Je kan je enkel kandidaat stellen als je hieraan voldoet.</span>
                            <span *ngIf="asTaskForm(task).skills.length > 1">Voor deze taak zijn er {{asTaskForm(task).skills.length}} vereisten. Vink aan wat voor jou van toepassing is. Je kan je enkel kandidaat stellen als je aan alle vereisten voldoet.</span>
                        </div>
                        <div class="form-check" *ngFor="let skill of asTaskForm(task).skills.controls">
                            <input class="form-check-input" type="checkbox" id="skill{{asSkillForm(skill).id.value}}" [formControl]="asSkillForm(skill).selected">
                            <label class="form-check-label" for="skill{{asSkillForm(skill).id.value}}">
                                {{asSkillForm(skill).name.value}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <!--buttons-->
            <div class="row mt-4">
                <div *ngIf="(numberSelected && taskForms && taskForms.length>0 && taskForms.valid) || (skillForms && skillForms.length>0 && skillForms.valid)" class="text-center">
                    <h5 *ngIf="numberSelected && taskForms && taskForms.length>0 && taskForms.valid">Top! Je kan je kandidaat stellen.</h5>
                    <h5 *ngIf="skillForms && skillForms.length>0 && skillForms.valid">Top! Je kan je kandidaat stellen.</h5>
                </div>

                <div class="col-lg-12 d-lg-flex justify-content-center">
                    <a class="btn btn-sm rounded-pill pxp-section-cta px-3 py-1 fs-6 my-2 mx-2" aria-label="Close" (click)="dismiss()" role="button">
                          Sluit venster
                    </a>
                    <a *ngIf="(numberSelected && taskForms && taskForms.length>0 && taskForms.valid) || (skillForms && skillForms.length>0 && skillForms.valid)" (click)="next()" class="btn btn-sm rounded-pill pxp-section-cta px-3 py-1 fs-6 my-2 mx-2">Stel je nu kandidaat</a>
                </div>
            </div>
        </div>

        <div *ngIf="step == 2">
            <h5>Vul dit formulier aan om je ook effectief kandidaat te stellen.</h5>
            <div class="mt-3">
                <div class="row g-3">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="required" for="firstName">Voornaam</label>
                            <input type="text" class="form-control" id="firstName" formControlName="firstName">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="required" for="lastName">Familienaam</label>
                            <input type="text" class="form-control" id="lastName" formControlName="lastName">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="required" for="postCode">Woonplaats (postcode)</label>
                            <input type="text" class="form-control" id="postCode" formControlName="postCode">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="required" for="email">E-mailadres</label>
                            <input type="email" class="form-control" id="email" formControlName="email">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="required" for="gsm">GSM / Telefoon</label>
                            <input type="text" class="form-control" id="gsm" formControlName="gsm">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="required" for="motivation">Motivatie</label>
                            <textarea type="text" class="form-control" rows="5" id="motivation" formControlName="motivation" placeholder="Laat hier beknopt weten waarom je dit vrijwilligerswerk wil doen..."></textarea>
                        </div>
                    </div>
                    <div class="col-md-12" *ngIf="isWeldoeners()">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="weldoeners" formControlName="radio">
                            <label class="form-check-label" for="weldoeners">
                Radio2 mag mij contacteren om met mijn Wéldoeners-verhaal op de radio te komen.
              </label>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="terms" formControlName="terms">
                            <label class="form-check-label" for="terms">
                Ik ga akkoord met de <a href="https://www.kanikhelpen.be/algemene-gebruiksvoorwaarden" target="_blank">Algemene Gebruiksvoorwaarden</a> en het <a href="https://www.kanikhelpen.be/privacy-verklaring" target="_blank">Privacybeleid</a>.
              </label>
                        </div>
                    </div>
                    <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" [useGlobalDomain]="false" formControlName="recaptcha">
                    </ngx-recaptcha2>
                    <div class="col-md-12 text-center">
                        <a (click)="back()" class="btn btn-sm rounded-pill pxp-section-cta px-3 py-1 fs-6 my-2 mx-2">Ga terug</a>

                        <button type="submit" [disabled]="!form.valid || sending" class="btn btn-primary pxp-single-job-apply-btn rounded-pill">
                            Verzenden <i *ngIf="sending" class="fa fa-spinner fa-spin"></i>
                        </button>

                        <!--<a (click)="next()" class="btn btn-sm rounded-pill pxp-section-cta px-3 py-1 fs-6 my-2">Verzenden</a>-->
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="step == 3">
            <span style="font-size: 1.25rem;">
                Erg bedankt om je kandidaat te stellen voor deze taak.
                <br />Je gegevens werden doorgestuurd naar de juiste persoon.
                <br /><br />
                <div *ngIf="numberSelected && taskForms && taskForms.length>0 && taskForms.valid" class="alert" role="alert" style="background-color: var(--pxpMainColorLight); border-color: var(--pxpLightGrayColor);">
                    <p class="pxp-text-light">
                        <span class="fw-bold opacity-100">
                            Belangrijk om weten
                        </span>
            <br /> Tijdens een noodsituatie of crisis kan de werkdruk ontzettend hoog zijn. De kans bestaat dat je dus niet meteen antwoord ontvangt. Gelieve zelf geen contact op te nemen, want dat verhoogt de druk onnodig. Als jouw hulp welkom is, word
            je zelf gecontacteerd.
            </p>
            <p class="pxp-text-light">
                Alvast bedankt voor je begrip.
            </p>
        </div>
        </span>
        <div class="row mt-4">
            <div class="col-lg-12 d-lg-flex justify-content-center mt-1">
                <a class="btn btn-sm rounded-pill pxp-section-cta px-3 py-1 fs-6" aria-label="Close" (click)="dismiss()" role="button">
                          Sluit venster
                    </a>
            </div>
        </div>
</div>
</form>
</div>