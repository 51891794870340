<app-navbar></app-navbar>

<section class="pt-100">
    <div class="pxp-container pb-100">
        <div class="row pxp-pl-80 justify-content-between align-items-top">
            <div class="col-lg-8 mx-auto mt-4">
                <div class="pxp-info-caption text-center mt-4 mt-md-0" data-aos-duration="600">
                    <h2 class="pxp-section-h2 mb-0">Enkele veelgestelde vragen</h2>
                    <h3 class="mb-4">voor lokale besturen</h3>
                </div>
            </div>
            <div class="col-lg-8 mx-auto mt-4 mb-5">
                <div data-aos="fade-up" data-aos-duration="600">
                    <h4 class="fw-bold ps-2">Over vrijwilligerswerk in crisistijden</h4>
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading02">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse02" aria-expanded="false" aria-controls="collapse02">
                                    Wat kunnen vrijwilligers betekenen in crisistijden?
                                </button>
                            </h2>
                            <div id="collapse02" class="accordion-collapse collapse" aria-labelledby="heading02" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        In tijden van crisis zijn er altijd heel wat mensen die spontaan hulp aanbieden. Ze kunnen snel ingeschakeld worden om praktische taken uit te voeren zodat de crisis beter of sneller onder controle komt.
                                    </p>
                                    <p>
                                        Omdat je met deze website kan werken met kant-en-klare vacatures, of eenvoudig een eigen vacature kan opstellen, weten kandidaat vrijwilligers meteen of ze op een goede manier van dienst kunnen zijn.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading03">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse03" aria-expanded="false" aria-controls="collapse03">
                                    Wat is de rol van het lokaal bestuur als het gaat om vrijwilligerswerk in
                                    crisistijden?
                                </button>
                            </h2>
                            <div id="collapse03" class="accordion-collapse collapse" aria-labelledby="heading03" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Het lokaal bestuur weet precies wat de noden zijn, waar vrijwilligers ingezet kunnen worden. Daarom is de stad of gemeente de geknipte partner om crisisvrijwilligerswerk in goede banen te leiden.
                                </div>
                            </div>
                        </div>
                    </div>

                    <h4 class="fw-bold ps-2 mt-5">Over de site kanikhelpen.be</h4>
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading01">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse01" aria-expanded="false" aria-controls="collapse01">
                                    Waarvoor dient deze website kanikhelpen.be?
                                </button>
                            </h2>
                            <div id="collapse01" class="accordion-collapse collapse" aria-labelledby="heading01" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Je kan als lokaal bestuur op deze website terecht voor 3 dingen:
                                    <ul class="mt-2">
                                        <li>
                                            Je kan deze website gebruiken als 'doorverwijzer' naar je eigen website of crisisplatform
                                        </li>
                                        <li>
                                            Je kan snel vacatures plaatsen in tijden van crisis of grote nood
                                        </li>
                                        <li>
                                            Je kan hier ook terecht voor het ontwikkelen van een eigen lokaal Vrijwilligerskorps.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading16">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16" aria-expanded="false" aria-controls="collapse16">
                                    Hoe kan je als lokaal bestuur deze website beginnen gebruiken?
                                </button>
                            </h2>
                            <div id="collapse16" class="accordion-collapse collapse" aria-labelledby="heading16" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        Als lokaal bestuur kan je <a [routerLink]="['/start']" routerLinkActive="router-link-active" style="text-decoration: underline; font-weight: 400;">op deze pagina</a> een aanvraag indienen om één of meerdere functionaliteiten
                                        van deze website te gebruiken.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading04">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse04" aria-expanded="false" aria-controls="collapse04">
                                    Welke voordelen biedt deze site voor ons als lokaal bestuur?
                                </button>
                            </h2>
                            <div id="collapse04" class="accordion-collapse collapse" aria-labelledby="heading04" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Gebruik je deze website om oproepen te plaatsen in tijden van crisis of grote nood, dan biedt de site deze voordelen:
                                    <ol>
                                        <li class="py-2">
                                            Je kan supersnel schakelen. Stel dat er iets gebeurt in de gemeente (wateroverlast, extreme hitte, een gezondheidscrisis, een toevloed van vluchtelingen,...) dan kan je met enkele klikken vrijwilligersvacatures plaatsen en de juiste mensen vinden voor
                                            het juiste profiel.
                                        </li>
                                        <li class="pb-2">
                                            Geen gedoe met nadenken over vrijwilligersvacatures, taken en profielen: alles staat klaar: de gemeente moet enkel selecteren welke oproep(en) ze online zet.
                                        </li>
                                        <li>
                                            Kandidaat-vrijwilligers doen zelf al een voorselectie. Je moet dus minder tijd investeren in het uitzoeken of de kandidaat wel past voor het profiel dat je zoekt.
                                        </li>
                                    </ol>
                                    Gebruik je deze website voor het ontwikkelen van een Vrijwilligerskorps, dan biedt de site ook tal van voordelen:
                                    <ol>
                                        <li class="py-2">
                                            Je hebt een overzicht van de leden van het team.
                                        </li>
                                        <li>
                                            Je kan de vrijwilligers via de website alerts sturen.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading05">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse05" aria-expanded="false" aria-controls="collapse05">
                                    Wij maken al gebruik van een eigen vrijwilligersplatform. Waarom zouden wij deze
                                    website dan gebruiken?
                                </button>
                            </h2>
                            <div id="collapse05" class="accordion-collapse collapse" aria-labelledby="heading05" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        Om eenvoudig gevonden te worden.
                                    </p>
                                    <p>
                                        Het doel van kanikhelpen.be is de tonnen vrijwillige energie die een crisis opwekt niet verloren te laten gaan, door alles op één centrale plek te verzamelen.
                                    </p>
                                    <p>
                                        Als je al een eigen platform gebruikt, dan is dat geen probleem. Je kan deze website dan als 'doorverwijzer' gebruiken. We zorgen dan voor een link naar jouw platform.
                                    </p>
                                    <p>
                                        We willen dat kandidaat-vrijwilligers in één oogwenk kunnen terugvinden waar ze iets kunnen doen. kanikhelpen.be coördineert en faciliteert, maar legt niks op.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading06">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse06" aria-expanded="false" aria-controls="collapse06">
                                    Kunnen we deze website ook gebruiken als er geen crisis is?
                                </button>
                            </h2>
                            <div id="collapse06" class="accordion-collapse collapse" aria-labelledby="heading06" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        Nee, als er geen nood noch crisis is, dan kan je hier geen oproepen lanceren maar maak je beter gebruik van <a href="https://www.vrijwilligerswerk.be" style="text-decoration: underline; font-weight: 400;" target="_blank">vrijwilligerswerk.be</a>.
                                    </p>
                                    <p>
                                        Toch een nuance, als je een Vrijwilligerskorps wil opzetten of het bestaande team opvolgen, dan blijft die mogelijkheid vanzelfsprekend altijd open. Dit gaat immers om een andere werking van de website, gerelateerd aan voorbereiding op crisissen.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading17">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse17" aria-expanded="false" aria-controls="collapse17">
                                    Moet ik wachten op een crisis om te starten met kanikhelpen.be?
                                </button>
                            </h2>
                            <div id="collapse17" class="accordion-collapse collapse" aria-labelledby="heading17" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        Nee, daar hoef je zeker niet op te wachten. We raden aan om nu al een account aan te maken. Zo staat alles al klaar voor als er iets gebeurt en kun je in een noodsituatie meteen vacatures lanceren. Tijdens noodsituaties ontbreekt meestal de tijd om accounts
                                        aan te maken.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading07">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse07" aria-expanded="false" aria-controls="collapse07">
                                    Kunnen we deze website ook gebruiken als het gaat om een plaatselijke nood of
                                    crisis?
                                </button>
                            </h2>
                            <div id="collapse07" class="accordion-collapse collapse" aria-labelledby="heading07" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Zeker, je moet niet wachten tot de nood of crisis een impact heeft in heel Vlaanderen. Je kan de website gebruiken voor lokale noden en/of crisissen.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading08">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse08" aria-expanded="false" aria-controls="collapse08">
                                    Kunnen we deze website gebruiken in een samenwerkingsverband met verschillende
                                    lokale besturen?
                                </button>
                            </h2>
                            <div id="collapse08" class="accordion-collapse collapse" aria-labelledby="heading08" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Dat kan zeker.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading09">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse09" aria-expanded="false" aria-controls="collapse09">
                                    Is deze website gratis te gebruiken?
                                </button>
                            </h2>
                            <div id="collapse09" class="accordion-collapse collapse" aria-labelledby="heading09" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Zeker en vast. kanikhelpen.be is een initiatief van en gefinancierd door de Vlaamse overheid.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h4 class="fw-bold ps-2 mt-5">Over het Vrijwilligerskorps</h4>
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading10">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                Wat is een Vrijwilligerskorps?
                            </button>
                        </h2>
                        <div id="collapse10" class="accordion-collapse collapse" aria-labelledby="heading10" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Een Vrijwilligerskorps is een door het lokaal bestuur samengesteld team van vrijwilligers (met verschillende competenties) dat snel oproep- en inzetbaar is moest er zich een crisis voordoen in de gemeente.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading11">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                                Waarom zou je als gemeente werken aan een Vrijwilligerskorps?
                            </button>
                        </h2>
                        <div id="collapse11" class="accordion-collapse collapse" aria-labelledby="heading11" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Lokale besturen zijn al erg actief op het gebied van vrijwilligerswerk. Door een Vrijwilligerskorps op te richten, heb je alvast een ploeg ter beschikking voor als…. Je kan bij een crisis snelheid ontwikkelen en snel ageren omdat je dan het team enkel
                                moet activeren.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading12">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
                                Kunnen we onze eigen invulling geven aan hoe wij een Vrijwilligerskorps willen bouwen?
                            </button>
                        </h2>
                        <div id="collapse12" class="accordion-collapse collapse" aria-labelledby="heading12" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p>
                                    Absoluut. De manier waarop je zelf aan de slag gaat met crisisvrijwilligers kies je volledig zelf. Wil je een getraind Vrijwilligerskorps oprichten? Dat kan. Ga je na in het bestaande vrijwilligersbestand welke competenties elke vrijwilliger heeft die
                                    nuttig kunnen zijn tijdens crisissen? Dat kan ook.
                                </p>
                                <p>
                                    Essentieel is dat de uitwerking aangepast is aan de eigenheid van je stad of gemeente.
                                </p>
                                <p>
                                    Meer informatie vind je in het<br /><a href="https://www.vlaanderenvrijwilligt.be/vrijwilligersbeleid/draaiboek/" style="text-decoration: underline; font-weight: 400;" target="_blank">Draaiboek
                                        ontwikkeling Vrijwilligerskorps</a>.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading13">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                                Is ieder lokaal bestuur in Vlaanderen verplicht om een Vrijwilligerskorps op te richten?
                            </button>
                        </h2>
                        <div id="collapse13" class="accordion-collapse collapse" aria-labelledby="heading13" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Nee, elk lokaal bestuur bepaalt dat zelf.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading14">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse14" aria-expanded="false" aria-controls="collapse14">
                                We plannen een Vrijwilligerskorps op te richten, welke ondersteuning kunnen we krijgen?
                            </button>
                        </h2>
                        <div id="collapse14" class="accordion-collapse collapse" aria-labelledby="heading14" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Het Vlaams Steunpunt Vrijwilligerswerk voorziet ondersteuning. Neem gerust contact op: <a href="mailto:kanikhelpen@vsvw.be" style="text-decoration: underline; font-weight: 400;">kanikhelpen@vsvw.be</a>.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading15">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse15" aria-expanded="false" aria-controls="collapse15">
                                Is er een gemeenschappelijk logo?
                            </button>
                        </h2>
                        <div id="collapse15" class="accordion-collapse collapse" aria-labelledby="heading15" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Ja, de Vlaamse overheid heeft een logo uitgewerkt. De instructies voor het gebruik daarvan vind je <a href="https://www.vlaanderenvrijwilligt.be/vrijwilligersbeleid/draaiboek/bouw-je-korps-uit/#LogovanhetVrijwilligerskorps:~:text=voor%20de%20vrijwilligerskorpsen.-,Lees%20de%20instructies,-hoe%20je%20dit"
                                    style="text-decoration: underline; font-weight: 400;" target="_blank">hier</a>.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pxp-info-caption text-center mt-4 mt-md-0" data-aos="fade-up" data-aos-duration="600">
                <h3 class="fw-bold mb-2">Vrijwilligerskorps oprichten?</h3>
                <p style="font-size: 1.25rem;">
                    Ontdek dan <a href="https://www.vlaanderenvrijwilligt.be/vrijwilligersbeleid/draaiboek/" target="_blank">hier</a> het Draaiboek ontwikkeling Vrijwilligerskorps.
                </p>
                <a href="https://www.vlaanderenvrijwilligt.be/vrijwilligersbeleid/draaiboek/" target="_blank">
                    <button class="btn rounded-pill pxp-section-cta">Draaiboek ontwikkeling Vrijwilligerskorps</button>
                </a>
            </div>
        </div>
        <div class="col-lg-12 mt-5">
            <div class="pxp-info-caption text-center mt-4 mt-md-0" data-aos="fade-up" data-aos-duration="600">
                <h3 class="fw-bold mb-2">Andere vraag of feedback?</h3>
                <p style="font-size: 1.25rem;">
                    Contacteer ons: <a href="mailto:kanikhelpen@vsvw.be" style="text-decoration: underline; font-weight: 400;">kanikhelpen@vsvw.be</a>
                </p>
            </div>
        </div>
    </div>
</section>