<footer class="pxp-main-footer">
    <div class="pxp-main-footer-top pt-5 pb-50" style="background-color: var(--pxpMainColorLight);">
        <div class="pxp-container">
            <div class="row justify-content-center justify-content-lg-start">
                <div class="col-lg-4 mb-4 text-center text-lg-start">
                    <div class="pxp-footer-logo">
                        <a href="https://www.vlaanderenvrijwilligt.be" class="pxp-animate" target="_blank"><img src="assets/images/logo-vlaanderen-vrijwilligt.svg" style="width:90%; max-height: 80px;"></a>
                    </div>
                </div>
                <div class="col-lg-4 mb-4 text-center text-lg-start">
                    <div class="pxp-footer-section mt-3">
                        <h3 class="mb-1">Contacteer ons</h3>
                        Vlaams Steunpunt Vrijwilligerswerk vzw<br> Breughelstraat 31-33<br> 2018 Antwerpen<br> tel. 03/218.59.01<br>
                        <a href="mailto:kanikhelpen@vsvw.be">kanikhelpen@vsvw.be</a><br />
                    </div>
                </div>
                <div class="col-10 col-md-8 col-lg-4 mb-2 text-center text-lg-start">
                    <div class="pxp-footer-section mt-3">
                        <h3 class="mb-1">Voor lokale besturen</h3>
                        <ul style="padding-left: 1rem!important;">
                            <li>
                                Wil je als lokaal bestuur starten met kanikhelpen.be, <a [routerLink]="['/start']" routerLinkActive="router-link-active">klik dan hier</a>. </li>
                            <li>
                                Wil je aanmelden om vacatures te beheren, <a href="https://nieuw.assistonline.eu" target="_blank">klik dan hier</a>. </li>
                            <li>
                                Heb je eerst nog een vraag, <a [routerLink]="['/voor-lokale-besturen']" routerLinkActive="router-link-active">klik dan hier</a>.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row d-lg-none mt-4">
                <div class="col-12 text-center">
                    © 2023 Vlaams Steunpunt Vrijwilligerswerk vzw
                </div>
                <div class="col-12 text-center">
                    <a routerLink="algemene-gebruiksvoorwaarden">Gebruiksvoorwaarden</a>&nbsp;&nbsp;-&nbsp;&nbsp;<a routerLink="privacy-verklaring">Privacyverklaring</a>
                </div>
                <div class="text-center mt-4 mb-5">
                    <img src="assets/images/vlaanderen_logo.png" style="width: 150px;">
                </div>
            </div>
            <div class="row d-none d-lg-flex">
                <div class="col-lg-2">
                </div>
                <div class="col-lg-8 d-flex align-items-center justify-content-center mb-5">
                    <span class="d-block" style="text-align: center;">
                        © 2023 Vlaams Steunpunt Vrijwilligerswerk vzw<br /><a routerLink="algemene-gebruiksvoorwaarden">Gebruiksvoorwaarden</a>&nbsp;&nbsp;-&nbsp;&nbsp;<a routerLink="privacy-verklaring">Privacyverklaring</a>
                    </span>
                </div>
                <div class="col-lg-2 text-end mb-5 pe-5">
                    <img src="assets/images/vlaanderen_logo.png" style="width: 150px;">
                </div>
            </div>
        </div>
    </div>
</footer>